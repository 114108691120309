export default [
  {
    header: 'Tableaux de bord',
    icon: 'HomeIcon',
    image: 'bar-graph.png',
    children: [
      // {
      //   title: 'Approvisionnements',
      //   route: 'tableaux-de-bord-appros',
      //   icon: 'PackageIcon',
      // },
      // {
      //   title: 'Stocks',
      //   route: 'tableaux-de-bord-stocks',
      //   icon: 'LayersIcon',
      // },
      {
        title: 'Principal',
        route: 'tableaux-de-bord-ventes',
        icon: 'LayersIcon',
      },
    ],
  },
]
