export default [
  {
    header: 'Tournées commerciale',
    icon: 'HomeIcon',
    image: 'tourners.png',
    children: [
      {
        title: 'Liste des tournées',
        route: 'tourners-liste',
        icon: 'ListIcon',
      },
      // {
      //   title: 'Statistiques',
      //   route: 'tourners-statistiques',
      //   icon: 'BarChart2Icon',
      // },
    ],
  },
]
