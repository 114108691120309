export default [
  {
    header: 'Points de vente',
    icon: 'HomeIcon',
    image: 'store.png',
    children: [
      {
        title: 'Liste',
        route: 'points-de-vente-liste',
        icon: 'ListIcon',
      },
      {
        title: 'PDV-Type',
        route: 'parametres-pdv-type',
        icon: 'SettingsIcon',
      },
      // {
      //   title: 'Les statistiques',
      //   route: 'points-de-vente-statistiques',
      //   icon: 'BarChart2Icon',
      // },
    ],
  },
]
