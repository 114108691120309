/*

NOTE: tag is not supported in horizontal menu

Array of object

Top level object can be:
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- nav Grp (top level grp is called header group) ---/

title
icon (if it's on top/second level)
children

/--- nav Item (top level item is called header link) ---/

icon (if it's on top/second level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)

*/
// import dashboard from './dashboard'
// import caisse from './caisse'
// import pharmacie from './pharmacie'
// import laboratoire from './laboratoire'
// import dossiersPatients from './dossiers-patients'
// import consultations from './consultations'
// import imagerie from './imagerie'
// import finance from './finance'
// import parametres from './parametres'

import tableauxDeBord from '@/navigation/horizontal/afridia/tableaux_de_bord'
import regions from '@/navigation/horizontal/afridia/regions'
import commerciaux from '@/navigation/horizontal/afridia/commerciaux'
import distributeurs from '@/navigation/horizontal/afridia/distributeurs'
import tourners from '@/navigation/horizontal/afridia/tourners'
import pointsDeVente from '@/navigation/horizontal/afridia/points_de_vente'
import ventes from '@/navigation/horizontal/afridia/ventes'
import stocks from '@/navigation/horizontal/afridia/stocks'
import equipements from '@/navigation/horizontal/afridia/equipements'
import geolocalisations from '@/navigation/horizontal/afridia/geolocalisations'
import parametres from '@/navigation/horizontal/afridia/parametres'

// Array of sections
export default [
  ...tableauxDeBord,
  ...regions,
  ...commerciaux,
  ...distributeurs,
  ...pointsDeVente,
  ...tourners,
  // ...ventes,
  // ...stocks,
  // ...equipements,
  ...geolocalisations,
  ...parametres,
]
