export default [
  {
    header: 'Distributeurs',
    icon: 'HomeIcon',
    image: 'box.png',
    children: [
      {
        title: 'Liste des distributeurs',
        route: 'distributeurs-liste',
        icon: 'ListIcon',
      },
    ],
  },
]
